const utils = require('../../utils/utils');
const utilsEcharts = require('../../utils/utilsEchars');
const {$eid, $eidx, $esid} = require('../imdas/list');
const cUtils = require('../../views/vizels/utility/c-utils');
const formatNumberWithString = require('@luxms/format-number-with-string');
import {mouseWatcher} from '../../libs/MouseWatcher';
import LoadFromResources from "../../views/components/LoadFromResources";
import * as dataManip from '../../data-manip/data-manip';
import * as dataUtils from '../../data-manip/data-utils';
import * as DataMatrix from '../../data-manip/DataMatrix';
import * as lpe from '@luxms/lpe';

const skin = require('../../skins/skin.json');
import {parse as wktParse} from "wellknown";
import {lpeRun} from '../../utils/lpeRun';
import L from 'leaflet';

const getBuildVersion = () => process.env.VERSION;
const getBuildDate = () => process.env.DATE;

module.exports = {...utils, ...utilsEcharts, L, wktParse,  $eid, $eidx, $esid, formatNumberWithString,
  mouseWatcher, ...cUtils, skin, lpeRun, getBuildVersion, getBuildDate, LoadFromResources, ...dataUtils, ...dataManip,
  ...DataMatrix, lpe
};
