//
// TODO: move this classes to /controllers
//

import { bi, FIND_LS, FIND_MS, FIND_PS, makeHashFromList, makeValue } from '../../utils/utils';
import { AppConfig, UrlState } from '@luxms/bi-core';
import { IDatasetModel, IVizelClass, IVizelConfig, IVizelProps } from './types';
import { data_engine } from '../../data-manip/data-manip';
import { urlExtractParams } from '../../utils/url-utils';
import { lpeRun } from '../../utils/lpeRun';
import * as lpe from '@luxms/lpe';
import { httpPost } from '../../repositories/http/data-storage';
import { IEntity, ISubspace, IValue, IVCPV, IVizel } from '../../defs/bi';
import { KoobFiltersService } from '../koob/KoobFiltersService';
import { AttachmentsService } from './AttachmentsService';
import { $eid } from '../../libs/imdas/list';
import DrilldownMenu from '../../views/dd-menu';

type IDataProvider = data_engine.IDataProvider;

enum VizelControllerState {
  READY,
  PREPARING_VCP_CLICK_ACTION,
  PREPARING_CHART_CLICK_ACTION,
}

export class VizelController {
  private _vizelConfig: IVizelConfig;
  private _dataset: IDatasetModel;
  private _subspace: any;
  private _defaultAction: any;
  private _vizelControllerState: VizelControllerState = VizelControllerState.READY;
  private _chartClickAction: (event: any, subspace: ISubspace) => void = null;

  public constructor(vizelConfig: IVizelConfig, defaultAction: string) {
    this._vizelConfig = vizelConfig;
    this._dataset = vizelConfig.dataset;
    this._subspace = null;
    this._defaultAction = defaultAction;
  }

  public getEventDescription(eventType): any {
    switch (eventType) {
      case 'onClickDataPoint':
        return this._vizelConfig.onClickDataPoint ? (Array.isArray(this._vizelConfig.onClickDataPoint) ? this._vizelConfig.onClickDataPoint : String(this._vizelConfig.onClickDataPoint)) : '';
    }
    const rawCfg = this._vizelConfig.getRaw();
    return rawCfg[eventType] ? String(rawCfg[eventType]) : '';
  }

  public async setAxes(subspace: ISubspace): Promise<any> {
    this._subspace = subspace;
  }

  private async _actionSpreadout(event, vcpv: IVCPV, axisName: string): Promise<void> {
    const {z, y, x, m, l, p, v} = vcpv;
    const cfg: IVizelConfig = this._vizelConfig.clone();

    let subspace: ISubspace;
    if (axisName === 'z') {
      subspace = bi.createSimpleSubspaceZYX([y], [x], this._subspace.zs);
    } else if (axisName === 'y') {
      subspace = bi.createSimpleSubspaceZYX([x], [z], this._subspace.ys);
    } else { // axisName === 'x'
      subspace = bi.createSimpleSubspaceZYX([z], [y], this._subspace.xs);
    }

    if (this._vizelConfig.getOption('DoNotApplyRangeToPopup')) {
      cfg.disableRange();
    }

    let spreadOutVizelType: string = 'line';
    if ('pointSpreadoutVizelType' in this._vizelConfig.getRaw()) {
      spreadOutVizelType = this._vizelConfig.getRaw()['pointSpreadoutVizelType'];
    }
    const modalContainer = (await import('../../views/modal-container')).modalContainer;

    const vizelConfig: IVizelConfig = this._dataset.createVizelConfig({
      view_class: spreadOutVizelType,
      display: cfg.getDisplay(),
      dataSource: {
        xAxis: subspace.xAxis,
        yAxis: subspace.yAxis,
        zAxis: subspace.zAxis,
        metrics: (FIND_MS(subspace.xs, subspace.ys, subspace.zs) ?? []).map(m => m.id),
        periods: (FIND_PS(subspace.xs, subspace.ys, subspace.zs) ?? []).map(m => m.id),
        locations: (FIND_LS(subspace.xs, subspace.ys, subspace.zs) ?? []).map(m => m.id)
      },
    });
    vizelConfig.colorResolver = {
      getColor: (e, v) => '#0000cc',
      getBgColor: (e, v) => '#0000cc',
      getColorPair: (e, v) => ({color: '#0000cc', bgColor: '#0000cc'}),
    };

    vizelConfig.controller = {
      handleVCPClick: (event, vcpv: IVCPV) => {
        this._actionShowdrilldownmenu(event, vcpv);                             // maximize only once: other vizels should show menu
      },
      handleChartClick: (event, subspace) => null,                              // skip
    };


    modalContainer.push({rawCfg: cfg.getRaw(), schemaName: cfg.dataset.schema_name}, z.title + ' / ' + y.title);
  }

  private async _actionEdit(event, vcpv: IVCPV): Promise<any> {
    const shell = (await import('../../views/Shell')).shell;
    const {z, y, x, v, m, l, p} = vcpv;
    const cfg: IVizelConfig = this._dataset.createVizelConfig({
      view_class: 'edt-value',
    });
    cfg.colorResolver = {
      getColor: (e: IEntity, v: IValue) => '#0000cc',
      getBgColor: (e: IEntity, v: IValue) => '#0000cc',
      getColorPair: (e, v) => ({color: '#0000cc', bgColor: '#0000cc'}),
    };
    cfg.controller = {
      handleVCPClick: (event, vcpv: IVCPV) => this._actionShowdrilldownmenu,    // maximize only once: other vizels should show menu
      handleChartClick: (event, subspace) => null,                              // skip
      handleClose: () => shell.setFlyoutVizel(null),
    };
    const dp: IDataProvider = this._dataset.getBiQuery().getDataProvider();
    shell.setFlyoutVizel({dp, cfg, subspace: bi.createSimpleSubspaceZYX([z], [y], [x])});
  }

  private _actionShowdrilldownmenu = async (event, vcpv: IVCPV, ...customItems: any[]) => {
    // TODO: remove, move to PopupVC
    // const shell = (await import('../../views/Shell')).shell;

    // кубы и остальные будут сильно зависеть от передаваемого конфига dataSource
    const subspacePtr = this._vizelConfig.getSubspacePtr();
    DrilldownMenu.instance.show(this._dataset, event, vcpv, subspacePtr, this._vizelConfig, ...customItems);
  }

  private _handleOpenDataset(event: any, vcpv: IVCPV, axisId: 'metrics' | 'locations' | 'periods'): void {
    const {m, l, p} = vcpv;

    if (axisId === 'metrics') {
      UrlState.navigate(m.config.onClick || m.config.onclick);
    } else if (axisId === 'locations') {
      UrlState.navigate(l.config.onClick || l.config.onclick);
    } else if (axisId === 'periods') {
      UrlState.navigate(p.config.onClick || p.config.onclick);
    }
  }

  private _replaceNavigateWithSubstitutions(eventDescription: string, repl): any {
    const replacedEventDescription = eventDescription.replace(/%(\d*)([a-zA-Z]+)/g, (fld, number, entityName) => {
      if (!(entityName in repl)) {                                              // does not have such letter
        return `%${number}${entityName}`;
      }

      if (number) {
        const n = parseInt(number);
        let s = encodeURIComponent(repl[entityName]);
        if (s.length > number) s = s.substr(0, n - 3) + '...';
        return s;
      } else {
        return encodeURIComponent(repl[entityName]);
      }
    });
    const params = urlExtractParams(replacedEventDescription);
    return params;
  }

  private _handleXClick(event: any, x: IEntity): void {
    let eventDescription: string = this.getEventDescription('onClickX');

    if (String(eventDescription).toLowerCase().startsWith('navigate')) {
      const params = this._replaceNavigateWithSubstitutions(eventDescription, {x: x ? x.id : ''});
      UrlState.navigate(params);
    }
  }

  private _handleVCPClick(event: any, vcpv: IVCPV): void {
    console.log(`VCP click {"metric_id": "${vcpv.m ? vcpv.m.id : null}", "loc_id": "${vcpv.l ? vcpv.l.id : null}", "period_id": "${vcpv.p ? vcpv.p.id : null}", "val": ${vcpv.v}}`);
    let eventDescription: any = this.getEventDescription('onClickDataPoint') || this._defaultAction;
    if ((Array.isArray(eventDescription) && typeof eventDescription[0] === 'string') || String(eventDescription).startsWith('lpe:')) {  // looks like lisp expression or lpe expression
      import('../../views/modal-container').then(modalContainerModule => {
        const setFn = (obj, key, value) => key.match(/^(.+?)\.(.+)$/) ? {
          ...obj,
          [RegExp.$1]: setFn(obj[RegExp.$1], RegExp.$2, value)
        } : {...obj, [key]: value};

        const additionalHash = {};

        if (additionalHash) {
          ['x', 'y', 'z'].forEach((i, index) => {
            if (!Array.isArray(vcpv[i]?.axisIds) || !vcpv[i]?.axisIds?.length) return;
            vcpv[i]?.axisIds.forEach((axisId, idx) => {
              additionalHash[axisId] = vcpv[i].ids[idx];
              if (axisId === 'measures') {
                additionalHash[axisId] = vcpv.v;
                additionalHash[vcpv[i].ids[idx]] = vcpv.v;
              }
            });
          });
        }

        const ctx = {
          ...vcpv,
          ...additionalHash,
          url: UrlState.getModel(),
          urlState: UrlState.getInstance(),
          openModal: (cfg, title) => modalContainerModule.modalContainer.pushVizelConfig(cfg, title ?? cfg.title),
          set: setFn,
          hash: makeHashFromList,
          setKoobFilter: (koobId, key, value) => KoobFiltersService.getInstance().setFilter(koobId, key, value),
          setKoobFilters: (koobId, ...args) => KoobFiltersService.getInstance().setFilters(koobId, makeHashFromList(...args)),
          toggleKoobFilters: (koobId, ...args) => KoobFiltersService.getInstance().toggleFilters(koobId, makeHashFromList(...args)),
          navigate: (...args) => UrlState.navigate(makeHashFromList(...args)),
          navigateUrl: (url: string) => typeof url === 'string' ? window.location.href = url : undefined,
          dashlet: (id) => this._dataset.dashletsHelper.getDash(id).getRawVizelConfig(),
          showDrilldownMenu: (...items) => this._actionShowdrilldownmenu(event, vcpv, ...items),
          'sf:menuItem': (ast, ctx, rs) => ({
            title: lpe.eval_lisp(ast[0], ctx, rs),
            action: () => {
              if (typeof ast[1] === 'string') lpe.eval_lpe(ast[1], ctx, rs);
              else lpe.eval_lisp(ast[1], ctx, rs);
            },
          }),
          attachment: async (attachmentId) => {
            const attachmentsService = AttachmentsService.createInstance(this._dataset.schema_name);
            await attachmentsService.whenReady();
            const attachment = $eid(attachmentsService.getModel(), attachmentId);
            if (!attachment) return null;
            return {
              title: attachment.title,
              action: () => {
                DrilldownMenu.hide();
                DrilldownMenu.getModalContainer().then(modalContainer => {
                  const dataSource = {
                    koob: this._vizelConfig.dataSource.koob,
                    filters: {...(vcpv.filters ?? {})},
                  };
                  [vcpv.x, vcpv.y].forEach((gAxis: any) => {
                    (gAxis.axisIds || []).forEach((axisId, idx) => {
                      const id = gAxis.ids[idx];
                      if (axisId === 'measures') dataSource.filters['$measures'] = ['=', id];
                      else if (id !== null) dataSource.filters[axisId] = ['=', id];
                    });
                  });

                  const cfg: IVizelConfig = this._dataset.createVizelConfig({
                    view_class: 'lookup-table',
                    lookupId: attachment.id,
                    dataSource,
                  } as any);

                  modalContainer.push({rawCfg: cfg.getRaw(), schemaName: cfg.dataset.schema_name}, '');
                });
              },
            };
          }
        };

        if (typeof eventDescription === 'string') {
          lpeRun(eventDescription, ctx);
        } else {
          lpe.eval_lisp(eventDescription, ctx);
        }
      });
      return;
    }

    let events = [];
    if (Array.isArray(eventDescription)) events = eventDescription;
    else events = [eventDescription];

    events.map(eventDescription => {
      if ((event as any).eventDescription) {                     // caller may override behaviour
        eventDescription = (event as any).eventDescription;
      }

      switch (String(eventDescription).toLowerCase()) {
        case 'maximize':
        case 'spreadout':
        case 'spreadoutx':
          this._actionSpreadout(event, vcpv, 'x');
          return;
        case 'spreadouty':
          this._actionSpreadout(event, vcpv, 'y');
          return;
        case 'spreadoutz':
          this._actionSpreadout(event, vcpv, 'z');
          return;
        case 'showdrilldownmenu':
        case 'menudrilldown':
          this._actionShowdrilldownmenu(event, vcpv);
          return;
        case 'openmetricdataset':
        case 'openparameterdataset':
          this._handleOpenDataset(event, vcpv, 'metrics');
          return;
        case 'openlocationdataset':
          this._handleOpenDataset(event, vcpv, 'locations');
          return;
        case 'openperioddataset':
          this._handleOpenDataset(event, vcpv, 'periods');
          return;
        case 'edit':
          this._actionEdit(event, vcpv);
          return;
      }

      let {m, l, p, v, x, y, z} = vcpv;

      // 'navigate?metrics=%m&locations=%l'
      if (String(eventDescription).toLowerCase().startsWith('navigate')) {
        const params = this._replaceNavigateWithSubstitutions(eventDescription, {                                     // replace %m %l %p
          m: m ? m.id : '',
          l: l ? l.id : '',
          p: p ? p.id : '',
          v: String(v),
          x: x ? x.id : '',
          y: x ? y.id : '',
          z: x ? z.id : '',
        });
        UrlState.navigate(params);
        return;
      }

      // 'lookup?id='
      if (String(eventDescription).toLocaleLowerCase().startsWith('lookup')) {
        const params = this._replaceNavigateWithSubstitutions(eventDescription, {                                     // replace %m %l %p
          m: m ? m.id : '',
          l: l ? l.id : '',
          p: p ? p.id : '',
          v: String(v),
          x: x ? x.id : '',
          y: x ? y.id : '',
          z: x ? z.id : '',
          mTitle: m?.title ?? '',
          lTitle: l?.title ?? '',
          pTitle: p?.title ?? '',
          mParentTitle: m?.parent?.title ?? '',
          lParentTitle: l?.parent?.title ?? '',
          pParentTitle: p?.parent?.title ?? '',
        });
        const dataset: IDatasetModel = this._dataset;
        if (params.m) m = dataset.M(params.m);
        if (params.l) l = dataset.L(params.l);
        if (params.p) p = dataset.P(params.p);

        if (!m || !l || !p) {
          console.warn('Invalid MLP for lookup');
          return;
        }

        const reqSubspaces: any = {
          version: '1.0',
          metrics: [m.id],
          locations: [l.id],
          periods: [p.id],
        };

        const url: string = AppConfig.fixRequestUrl(`/api/vcp/${this._dataset.schema_name}/actions/`);
        httpPost<any>(url, reqSubspaces).then(async (data) => {
          let vcpActions: any[] = (Array.isArray(data) ? data : data.sources).filter(e => e.src_type === 'vcp-lookup-table');
          if (params.id) vcpActions = vcpActions.filter(e => String(e.id) === params.id);
          let vcpAction = vcpActions[0];
          if (!vcpAction) {
            console.warn('No action found for ClickDataPoint:' + String(eventDescription));
            return;
          }
          import('../../views/modal-container').then(modalContainerModule => {
            const options: string[] = ['LookupExternalFilter'];
            const subspace: ISubspace = bi.createSimpleSubspaceZYX([p], [m], [l]);
            const cfg: IVizelConfig = dataset.createVizelConfig({
              view_class: 'lookup-table',
              lookupId: vcpAction.id,
              options,
              dataSource: {
                xAxis: subspace.xAxis,
                yAxis: subspace.yAxis,
                zAxis: subspace.zAxis,
                metrics: (FIND_MS(subspace.xs, subspace.ys, subspace.zs) ?? []).map(m => m.id),
                periods: (FIND_PS(subspace.xs, subspace.ys, subspace.zs) ?? []).map(m => m.id),
                locations: (FIND_LS(subspace.xs, subspace.ys, subspace.zs) ?? []).map(m => m.id)
              }
            } as any);

            modalContainerModule.modalContainer.push({
              rawCfg: cfg.getRaw(),
              schemaName: cfg.dataset.schema_name
            }, params.title);
          });
        });
        return;
      }


      if (eventDescription && typeof eventDescription === 'object') {
        // TODO: eventDescription.type === 'navigate', payload: vizelConfig
        if (!eventDescription?.title) UrlState.navigate(eventDescription);
        else this._actionShowdrilldownmenu(event, vcpv);
      }
    });
  }

  private _handleChartClick(event: any, subspace: ISubspace): void {
    if (this._chartClickAction) this._chartClickAction(event, subspace);
  }

  private _postponedActionTimerId: number = null;

  private _scheduleAction(action: () => void): void {
    if (this._postponedActionTimerId !== null) {
      window.clearTimeout(this._postponedActionTimerId);
    }
    this._postponedActionTimerId = window.setTimeout(() => {
      try {
        action();
      } catch (err) {
        console.error(err);
      }
      // reset all
      this._postponedActionTimerId = null;
      this._vizelControllerState = VizelControllerState.READY;
    }, 55);
  }

  public handleXClick(event: any, x: IEntity): void {
    switch (this._vizelControllerState) {
      case VizelControllerState.READY:
      case VizelControllerState.PREPARING_CHART_CLICK_ACTION:              // if scheduled chart click we annulate it and reschedule VCP click
        this._scheduleAction(() => this._handleXClick(event, x));
        this._vizelControllerState = VizelControllerState.PREPARING_VCP_CLICK_ACTION;
        break;
      case VizelControllerState.PREPARING_VCP_CLICK_ACTION:
        // ignore other VCP clicks
        break;
    }
  }

  public handleVCPClick(event: any, vcpv: IVCPV): void {
    switch (this._vizelControllerState) {
      case VizelControllerState.READY:
      case VizelControllerState.PREPARING_CHART_CLICK_ACTION:              // if scheduled chart click we annulate it and reschedule VCP click
        this._scheduleAction(() => this._handleVCPClick(event, vcpv));
        this._vizelControllerState = VizelControllerState.PREPARING_VCP_CLICK_ACTION;
        break;
      case VizelControllerState.PREPARING_VCP_CLICK_ACTION:
        // ignore other VCP clicks
        break;
    }
  }

  public handleChartClick(event: any, subspace: any): void {
    switch (this._vizelControllerState) {
      case VizelControllerState.READY:
        this._scheduleAction(() => this._handleChartClick(event, subspace));
        break;
      case VizelControllerState.PREPARING_CHART_CLICK_ACTION:             // ignore repeated chart clicks
      case VizelControllerState.PREPARING_VCP_CLICK_ACTION:               // ignore chart click when VCP click scheduled
        break;
    }
  }

  // HACK
  public setChartClickAction(chartClickAction: any): void {
    this._chartClickAction = chartClickAction;
  }
}


export default VizelController;
