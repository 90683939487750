import { BaseService, AppConfig, createSingleton, UrlState, srv, AuthenticationService } from '@luxms/bi-core';
import { themes as defaultThemes} from '@luxms/bi-face';
import createLoadingGif, { createCSSVariables } from '../utils/createLoadingGif';
import ResourceByNameService from '../services/ResourceByNameService';
const skin: any = require('../skins/skin.json');

const COLOR_PALETTE = skin.colorPallete ?? [
  '#AA6FAC',
  '#E85498',
  '#4F4F9B',
  '#4AB6E8',
  '#E07921',
  '#5FB138',
  '#F05045',
  '#F2BB05',
  '#9797C4',
];
export interface IThemeVM {
  error: string;
  loading: boolean;
  themes: any;
  currentTheme: any;
  currentThemeId: string;
  colorPalette: string[];
}


function mergeThemes(themes: any, additionalThemes: any): any {
  themes = {...themes};

  for (let theme in themes) {
    if (additionalThemes[theme] === null || additionalThemes[theme] === false) {                    // Если выставили null или false - удаляем эту тему
      delete themes[theme];
    } else if (typeof additionalThemes[theme] === 'object') {                                       // если выставили объект - то мержим
      themes[theme] = {...themes[theme], ...additionalThemes[theme]};
    }
  }

  // Возможно, в additionalThemes объявлены новые темы
  for (let theme in additionalThemes) {
    if (typeof additionalThemes[theme] === 'object' && !themes[theme]) {
      themes[theme] = additionalThemes[theme];
    }
  }

  return themes;
}

/**
 * @class
 * @instance
 * @description Сервис подтягивает из ресурсов themes.json, темы могут лежать как в ds_res, так и в отдельной папке датасета ds_... .
 * Если в ресурсах ничего нет, тема берется из bi-face,
 */
export class ThemeVC extends BaseService<IThemeVM> {
  // private _themesJsonService: ResourceFileWatcherService;
  private _themesJsonService: ResourceByNameService;
  private readonly _authenticationService: AuthenticationService;
  private readonly _usersService: srv.adm.UsersService;

  protected constructor() {
    super({
      error: null,
      loading: true,
      themes: defaultThemes,
      currentTheme: defaultThemes.light,
      currentThemeId: 'light',
      colorPalette: defaultThemes.light?.themeBuilder?.color || COLOR_PALETTE
    });
    UrlState.getInstance().subscribe('displayMode', this._onServiceUpdated);
    AppConfig.getInstance().subscribeUpdates(this._onServiceUpdated);
    // this._themesJsonService = new ResourceFileWatcherService('ds_res', 'themes.json');
    this._themesJsonService = new ResourceByNameService('themes.json');
    this._authenticationService = AuthenticationService.getInstance();
    this._usersService = srv.adm.UsersService.getInstance();

    this._authenticationService.subscribeUpdates(this._onSideServiceUpdated);
    this._usersService.subscribeUpdates(this._onSideServiceUpdated);

    this._themesJsonService.subscribeUpdates(this._onServiceUpdated);
    this._onServiceUpdated();
  }

  protected _dispose() {
    this._themesJsonService.unsubscribe(this._onServiceUpdated);
    this._themesJsonService.release();
    this._themesJsonService = null;
    AppConfig.getInstance().unsubscribe(this._onServiceUpdated);
    super._dispose();
  }

  private _onSideServiceUpdated = () => {
    const authModel = this._authenticationService.getModel();
    const usersModel = this._usersService.getModel();
    const themeModel = this.getModel();
    if (authModel.error || usersModel.error || authModel.loading || usersModel.loading || themeModel.loading || themeModel.error) return null;
    const userId = authModel.userId;
    const currentUser = usersModel.find(item => item.id === userId);
    const userTheme = currentUser.config.themeId;
    if (userTheme && themeModel.currentThemeId !== userTheme) this.setTheme(userTheme);
  }

  private _onServiceUpdated = () => {
    const isCustomEnabled = UrlState.getModel().displayMode !== 'luxmsbi';
    const appConfig: AppConfig['MODEL'] = AppConfig.getModel();
    const themesJson = this._themesJsonService.getModel();
    if (appConfig.error) return this._updateWithError(appConfig.error || themesJson.error);
    if (appConfig.loading || themesJson.loading) return this._updateWithLoading();
    //
    let themes: any = defaultThemes;
    if (isCustomEnabled && skin.themes) {
      // bug with sass-loader
      // Ругается sass-loader (не может преобразовать null в объект) если в skin.json в качестве значения какой то из тем указать null.
      themes = mergeThemes(themes, skin.themes);
    }
    if (isCustomEnabled && appConfig.themes) {
      themes = mergeThemes(themes, appConfig.themes);
    }
    if (isCustomEnabled && !themesJson.error && themesJson.content) {
      themes = mergeThemes(themes, themesJson.content);
    }

    let currentThemeIdFromLC = localStorage.getItem('theme');
    let enabledThemesKeys = Object.keys(themes).filter(key => themes[key] !== null);
    let currentThemeId = currentThemeIdFromLC && themes[currentThemeIdFromLC] !== undefined
        ? currentThemeIdFromLC
        : (themes['light'] !== null ? 'light' : themes[enabledThemesKeys.length ? enabledThemesKeys[0] : '']);
    if (!(currentThemeId in themes) || themes[currentThemeIdFromLC] !== undefined ) currentThemeId = Object.keys(themes)[0];
    const currentTheme = themes[currentThemeId];
    createCSSVariables(themes);
    this._applyThemeVariables(currentTheme, currentThemeId);
    this._updateWithData({themes, currentTheme, currentThemeId, colorPalette: currentTheme?.themeBuilder?.color || COLOR_PALETTE});
  }

  public setTheme(currentThemeId: string) {
    let currentTheme = this._model.themes[currentThemeId];
    if (currentTheme) {
      localStorage.setItem('theme', currentThemeId);
      this._applyThemeVariables(currentTheme, currentThemeId);
      this._updateModel({currentTheme, currentThemeId, colorPalette: currentTheme?.themeBuilder?.color || COLOR_PALETTE});
    }
  }

  public static applyThemeToElement(themeId: string, theme: any, element: HTMLElement) {
    element.setAttribute('data-theme', themeId);
    element.className = element.className.split(' ').filter(s => !s.startsWith('theme-')).join(' ') + ` theme-${themeId}`;
    // const style = element.style;
    // for (let key in theme) {
    //   style.setProperty('--' + key.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`), theme[key]);
    // }
  }

  private _applyThemeVariables(theme: any, themeId: string) {
    createLoadingGif(theme.primary);
    ThemeVC.applyThemeToElement(themeId, theme, document.body);

    // ...
    let iframes = Array.from(document.getElementsByTagName('iframe'));
    iframes.forEach(iframe => {
      const src = iframe.getAttribute('src');
      const newSrc = src.replace(/theme=(\w+)/, `theme=${themeId}`);
      if (src !== newSrc) {
        iframe.setAttribute('src', newSrc);
      }
    });
  }

  public static getInstance: () => ThemeVC = createSingleton<ThemeVC>(() => new ThemeVC(), '__themeVC');
}
